import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { customComponents } from '../utils/customComponents'

export const TextSection = ({ slice }) => {
  const isUpdatedSlice = !!slice.primary.text_field

  const backgroundColor = isUpdatedSlice
    ? slice.primary.blue_background
      ? '#f3f9ff'
      : undefined
    : slice.primary.background_color

  const backgroundStyle = isUpdatedSlice
    ? {}
    : { background: 'url(' + slice.primary.background_image?.url + ')' }

  const textContent = isUpdatedSlice
    ? slice.primary.text_field?.richText
    : slice.primary.copy?.richText

  const isLeftAligned = isUpdatedSlice && slice.primary.left_aligned
  console.log('isLeftAligned', isLeftAligned)

  return (
    <section
      className={`TextSection `}
      style={{
        backgroundColor,
        ...backgroundStyle,
      }}
    >
      <div className={isLeftAligned ? 'Container' : 'ContainerCentered'}>
        <PrismicRichText field={textContent} components={customComponents} />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyTextSection on PrismicPageDataBodyTextSection {
    id
    primary {
      background_colour
      background_image {
        url
      }
      copy {
        richText
      }
    }
  }
  fragment UpdatedPageDataBodyTextSection on PrismicUpdatedPageDataBodyTextSection {
    id
    primary {
      text_field {
        richText
      }
      blue_background
      left_aligned
    }
  }
`
